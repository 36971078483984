import * as React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../createDashboardLazyWidget";

const DashboardLiveTutoringTotalHoursChart = createDashboardLazyWidget({
  displayName: "DashboardLiveTutoringTotalHoursChart",
  contentHeight: 310,
  caption: "Live tutoring hours",
  clarification:
    "Hours used for Live Tutoring lessons during the time frame selected.",
  testid: struct.adminDash.dashboard.tutoring.hoursChart,
  useRender: (apiQuery, resetFilters) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/live_hours/",
      apiQuery,
      true
    );
    React.useEffect(() => trackEvent("ll_load_hours_chart", undefined), []);
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
        resetFilters={resetFilters}
        testid={struct.adminDash.dashboard.tutoring.hoursChart}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Hours" />
      </WidgetContentNew>
    );
  }
});

export default DashboardLiveTutoringTotalHoursChart;
