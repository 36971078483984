import React, { MutableRefObject } from "react";

export interface DashboardGuideContextProps {
  headerRef?: MutableRefObject<HTMLDivElement | null>;
  filtersRef?: MutableRefObject<HTMLDivElement | null>;
  generalReportsRef?: MutableRefObject<HTMLDivElement | null>;
  liveLessonsRef?: MutableRefObject<HTMLDivElement | null>;
  writingLabRef?: MutableRefObject<HTMLDivElement | null>;
}

export default React.createContext<DashboardGuideContextProps>({});
