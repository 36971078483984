import * as React from "react";

import AnchorIntersectionObserver from "~/components/core/AnchorIntersectionObserver";
import { PERIOD, PERIODS } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";
import { Range } from "~/declarations/filters";
import { segment } from "~/utils/segment";

import { useRequiredAuthContext } from "../auth/common";
import DashboardActiveStudentsChart from "./DashboardActiveStudentsChart";
import DashboardContext from "./DashboardContext";
import DashboardFilteredStatistic from "./DashboardFilteredStatistic";
import DashboardFilterToolbar from "./DashboardFilterToolbar";
import DashboardGuide from "./DashboardGuide";
import DashboardGuideContext from "./DashboardGuide/DashboardGuideContext";
import DashboardHeader from "./DashboardHeader";
import DashboardLiveTutoringStatistic from "./DashboardLiveTutoringStatistic";
import DashboardLiveTutoringTotalCompletedChart from "./DashboardLiveTutoringTotalCompletedChart";
import DashboardLiveTutoringTotalHoursChart from "./DashboardLiveTutoringTotalHoursChart";
import DashboardLiveVsWritingLabChart from "./DashboardLiveVsWritingLabChart";
import DashboardPowerUsersHours from "./DashboardPowerUsers/DashboardPowerUsersHours";
import DashboardPowerUsersSessions from "./DashboardPowerUsers/DashboardPowerUsersSessions";
import { useDashboardQueryState } from "./dashboardQueryState";
import DashboardSubjectStats from "./DashboardSubjectStats/DashboardSubjectStats";
import DashboardTotalHoursChart from "./DashboardTotalHoursChart";
import DashboardTotalSessionsChart from "./DashboardTotalSessionsChart";
import DashboardWritingLabHoursChart from "./DashboardWritingLabHoursChart";
import DashboardWritingLabPapersCompletedChart from "./DashboardWritingLabPapersCompletedChart";
import DashboardWritingLabStatistic from "./DashboardWritingLabStatistic";
import useScaleRestrictions from "./useScaleRestrictions";

const Dashboard: React.FC = (): JSX.Element => {
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const filtersRef = React.useRef<HTMLDivElement | null>(null);
  const generalReportsRef = React.useRef<HTMLDivElement | null>(null);
  const liveLessonsRef = React.useRef<HTMLDivElement | null>(null);
  const writingLabRef = React.useRef<HTMLDivElement | null>(null);

  const labsStatistic = React.useRef<HTMLDivElement | null>(null);
  const hoursUsed = React.useRef<HTMLDivElement | null>(null);
  const papersCompleted = React.useRef<HTMLDivElement | null>(null);
  const liveLessons = React.useRef<HTMLDivElement | null>(null);
  const liveLessonsStat = React.useRef<HTMLDivElement | null>(null);
  const lessonHours = React.useRef<HTMLDivElement | null>(null);
  const totalLessons = React.useRef<HTMLDivElement | null>(null);
  const filteredStatistic = React.useRef<HTMLDivElement | null>(null);
  const hoursUsedSessions = React.useRef<HTMLDivElement | null>(null);
  const sessionsCompleted = React.useRef<HTMLDivElement | null>(null);
  const activeStudents = React.useRef<HTMLDivElement | null>(null);
  const powerUsers = React.useRef<HTMLDivElement | null>(null);

  const [
    {
      fromDt: fromDtFilter,
      tillDt: tillDtFilter,
      subjects: subjectFilter,
      moderation: lessonFilter,
      period: periodFilter,
      scale: scaleFilter
    }
  ] = useDashboardQueryState();

  const presetRange = RANGE_GETTERS[periodFilter];
  const rangeFilter: Range = presetRange
    ? presetRange()
    : [fromDtFilter, tillDtFilter];

  const selectedPeriod = PERIODS.find(({ period }) => period === periodFilter);

  if (!selectedPeriod) {
    console.error(`unknown period ${periodFilter}`);
    throw new Error(`unknown period ${periodFilter}`);
  }

  const restrictedScales = useScaleRestrictions(rangeFilter);

  const {
    selectedUmbrella: {
      umbrellaAccName,
      billingMode,
      timeLimitMinutes: limitMinutes,
      expirationDate: dateExpires
    }
  } = useRequiredAuthContext();

  React.useEffect(() => {
    segment.page("Dashboard");
  }, []);

  return (
    <DashboardContext.Provider
      key={umbrellaAccName}
      value={{
        filter: {
          fromDt: rangeFilter[0]?.toISOString(),
          tillDt: rangeFilter[1]?.toISOString(),
          scale: scaleFilter,
          lesson: lessonFilter,
          subjects: subjectFilter ?? undefined
        },
        selectedPeriod,
        umbrellaLimits: {
          billingMode,
          minutes: limitMinutes,
          expires: dateExpires
        },
        explicit: true,
        restrictedScales
      }}
    >
      <DashboardGuideContext.Provider
        value={{
          headerRef,
          filtersRef,
          generalReportsRef,
          liveLessonsRef,
          writingLabRef
        }}
      >
        <AnchorIntersectionObserver
          refs={[
            { id: "", ref: headerRef, forceOffset: 0 },
            { id: "labsStatistic", ref: labsStatistic },
            { id: "hoursUsed", ref: hoursUsed },
            { id: "papersCompleted", ref: papersCompleted },
            { id: "liveLessons", ref: liveLessons },
            { id: "liveLessonsStat", ref: liveLessonsStat },
            { id: "lessonHours", ref: lessonHours },
            { id: "totalLessons", ref: totalLessons },
            { id: "filteredStatistic", ref: filteredStatistic },
            { id: "hoursUsedSessions", ref: hoursUsedSessions },
            { id: "sessionsCompleted", ref: sessionsCompleted },
            { id: "activeStudents", ref: activeStudents },
            { id: "powerUsers", ref: powerUsers }
          ]}
        />
        <DashboardGuide />
        <div id="header" ref={headerRef}>
          <DashboardHeader />
        </div>
        <DashboardFilterToolbar ref={filtersRef} rangeFilter={rangeFilter} />
        <div className="flex flex-col gap-[48px] flex-grow mx-0 sm:mx-[30px] h-full pt-[40px]">
          {!subjectFilter && (
            <div className="flex flex-col gap-[24px]" ref={generalReportsRef}>
              <div>
                <div className="h1">General usage</div>
                <div className="paragraph mt-[4px]">
                  This section includes both Live Tutoring and Writing Labs.
                  Scroll down for separate breakdowns.
                </div>
              </div>

              <div
                id="filteredStatistic"
                ref={filteredStatistic}
                className={statsContainerClass}
              >
                <DashboardFilteredStatistic />
              </div>
              <div id="hoursUsedSessions" ref={hoursUsedSessions}>
                <DashboardTotalHoursChart />
              </div>
              <div id="sessionsCompleted" ref={sessionsCompleted}>
                <DashboardTotalSessionsChart />
              </div>
              <div id="activeStudents" ref={activeStudents}>
                <DashboardActiveStudentsChart />
              </div>
              <div id="powerUsers" ref={powerUsers}>
                <div className="flex flex-col sm:flex-row gap-[32px]">
                  <DashboardPowerUsersSessions />
                  <DashboardPowerUsersHours />
                </div>
              </div>
            </div>
          )}

          <div ref={liveLessonsRef} className="flex flex-col gap-[24px]">
            <div className="h1">Live Tutoring</div>
            <div
              id="liveLessonsStat"
              ref={liveLessonsStat}
              className={statsContainerClass}
            >
              <DashboardLiveTutoringStatistic />
            </div>
            <DashboardSubjectStats />
            <div id="lessonHours" ref={lessonHours}>
              <DashboardLiveTutoringTotalHoursChart />
            </div>
            <div id="totalLessons" ref={totalLessons}>
              <DashboardLiveTutoringTotalCompletedChart />
            </div>
          </div>

          {!subjectFilter && (
            <div ref={writingLabRef} className="flex flex-col gap-[24px]">
              <div className="h1">Writing Lab</div>
              <div
                id="labsStatistic"
                ref={labsStatistic}
                className={statsContainerClass}
              >
                <DashboardWritingLabStatistic />
              </div>
              <div id="hoursUsed" ref={hoursUsed}>
                <DashboardWritingLabHoursChart />
              </div>
              <div id="papersCompleted" ref={papersCompleted}>
                <DashboardWritingLabPapersCompletedChart />
              </div>
              <div id="liveLessons" ref={liveLessons}>
                <DashboardLiveVsWritingLabChart />
              </div>
            </div>
          )}
        </div>
      </DashboardGuideContext.Provider>
    </DashboardContext.Provider>
  );
};

export default Dashboard;

const statsContainerClass =
  "flex gap-[16px] self-stretch flex-col sm:flex-row w-full items-center sm:items-start";
